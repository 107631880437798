<template>
  <div class="addBankCard">
    <mt-header :title="$t('addBankCard')">
      <router-link to slot="left">
        <mt-button @click="$router.back(-1)">
          <img src="@/assets/back.png" height="20" width="20" slot="icon">
          {{ $t('back') }}
        </mt-button>
      </router-link>
      <call-phone slot="right" />
    </mt-header>
    <div class="content">
      <div class="item">
        <div class="title">{{ $t('bankAccountHolder') }}</div>
        <mt-field
          :placeholder="$t('bankAccountHolderPlaceholder')"
          :state="bankAccountHolderState"
          v-model="form.bankAccountHolder"
        ></mt-field>
      </div>
      <div class="item">
        <div class="title">{{ $t('bank') }}</div>
        <mt-field
          :placeholder="$t('bankPlaceholder')"
          :state="bankCodeState"
          readonly
          v-model="(Enum.bank.filter(e => e.code == form.bankCode)[0]||{}).name"
          @click.native="onPopupPicker(Enum.bank, (e) => {
            form.bankCode = e.code;
          })"
        >
          <i class="iconfont">&#xe62a;</i>
        </mt-field>
      </div>
      <div class="item">
        <div class="title">{{ $t('bankAccountNo') }}</div>
        <div class="tips">{{ $t('bankAccountNoTips') }}</div>
        <mt-field
          :placeholder="$t('bankAccountNoPlaceholder')"
          :state="bankAccountNoState"
          v-model="form.bankAccountNo"
        ></mt-field>
      </div>
      <div class="item">
        <div class="title">{{ $t('bvn') }}</div>
        <mt-field
          :placeholder="$t('bvnPlaceholder')"
          :state="bvnState"
          v-model="form.bvn"
        ></mt-field>
      </div>
    </div>
    <div class="bottom">
      <mt-button type="primary" size="large" @click="submit">{{
        $t("submit")
      }}</mt-button>
    </div>
    <popup-picker ref="popupPicker" />
  </div>
</template>

<script>
import callPhone from '@/components/callPhone.vue'
import mixin from '../../step/mixin'
import popupPicker from '@/components/popupPicker.vue'
import { nameRules, emptyRules } from '../../../utils/rules'
export default {
  name: 'AddBankCard',
  mixins: [mixin],
  components: {
    callPhone,
    popupPicker,
  },
  data () {
    return {
      isCheck: false,
      form: {
        bankAccountHolder: '',
        bankCode: '',
        bankAccountNo: '',
        bvn: '',
      }
    }
  },
  computed: {
    bankAccountHolderState() {
      return this.isCheck ? nameRules(this.form.bankAccountHolder) : null;
    },
    bankCodeState() {
      return this.isCheck ? emptyRules(this.form.bankCode) : null;
    },
    bankAccountNoState() {
      return this.isCheck ? emptyRules(this.form.bankAccountNo) : null;
    },
    bvnState() {
      return this.isCheck ? emptyRules(this.form.bvn) : null;
    },
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.GET_BANK(); // 获取银行
    },
    // 校验输入内容
    validation(list) {
      if (list.indexOf('bankAccountHolder') >= 0 && this.bankAccountHolderState != "success") {
        return this.$toast(this.bankAccountHolderState == "warning"
          ? this.$t("bankAccountHolderPlaceholder")
          : this.$t("bankAccountHolderError"));
      }
      if (list.indexOf('bankCode') >= 0 && this.bankCodeState != "success") {
        return this.$toast(this.bankCodeState == "warning"
          ? this.$t("bankPlaceholder")
          : this.$t("bankError"));
      }
      if (list.indexOf('bankAccountNo') >= 0 && this.bankAccountNoState != "success") {
        return this.$toast(this.bankAccountNoState == "warning"
          ? this.$t("bankAccountNoPlaceholder")
          : this.$t("bankAccountNoError"));
      }
      if (list.indexOf('bankAccountNo') >= 0 && this.bvnState != "success") {
        return this.$toast(this.bvnState == "warning"
          ? this.$t("bvnPlaceholder")
          : this.$t("bvnError"));
      }
    },
    async submit() {
      this.isCheck = true;
      var error = this.validation(['bankAccountHolder', 'bankCode', 'bankAccountNo', 'bvn']);
      if (error) return this.$toast(error);
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      this.$NProgress.start()
      await new Promise(resolve => {
        setTimeout(() => {
          resolve()
        }, 1000)
      })
      this.$indicator.close();
      this.$NProgress.done()
    },
  }
}
</script>

<style lang="scss" scoped>
.addBankCard {
  background-color: #FBFBFF;
  min-height: 100vh;
  .content {
    padding: 10px;
    > .item {
      margin-top: 20px;
      .mint-cell {
        background: white;
        /deep/ .mint-cell-wrapper {
          background: none;
        }
      }
      .prompt {
        position: relative;
        font-size: 16px;
        color: #1D1D1D;
        margin: 15px 6px 40px 6px;
        .val {
          &::after {
            content: '*';
            font-size: 14px;
            vertical-align: top;
            color: #E92121;
          }
        }
        .example {
          position: absolute;
          right: 0px;
          top: 0px;
          text-decoration: underline;
          font-weight: 500;
          font-size: 16px;
          color: #239DE8;
        }
      }
      .image {
        margin: 0 100px;
      }
      .tip {
        font-size: 12px;
        color: #ADB0B6;
        font-size: 15px;
        text-align: center;
      }
      .title {
        margin: 6px 15px;
        font-size: 16px;
        color: #1D1D1D;
        &::after {
          content: '*';
          font-size: 14px;
          vertical-align: top;
          color: #E92121;
        }
      }
      .tips {
        font-size: 13px;
        color: #239DE8;
        margin: 0px 15px 5px 15px;
      }
      .mint-field {
        border-radius: 6px;
        min-height: 46px;
      }
    }
  }
  .iconfont {
    margin: 0 5px;
    color: #F15234;
  }
  .bottom {
    padding: 40px 10px;
    .mint-button {
      border-radius: 6px;
    }
  }
}
</style>